<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style="min-height: calc(100vh - 83px);"
  >
    <q-card flat bordered style="width:1024px" class="q-mt-xl">
      <q-form @submit="confirm = true">
        <q-card-section class="bg-indigo-1 q-gutter-md">
          <p class="text-grey text-h6">IDENTITAS :</p>
          <div class="row items-center justify-between">
            <a class="item col-6 text-left">Jenjang</a>
            <q-select
              :options="$LIST_JENJANG"
              v-model="selJenjang"
              outlined
              disable
              dense
              filled
              class="col-6"
            ></q-select>
          </div>
          <div class="row items-center justify-between">
            <a class="item col-6 text-left">Nama</a>
            <q-input
              filled
              dense
              @change="isChange = true"
              outlined
              v-model="guruDetail.nama"
              class="col-6"
              lazy-rules
              :rules="[
                (val) => (val && val.length > 0) || 'Nama Tidak Boleh Kosong',
              ]"
            ></q-input>
          </div>
          <div class="row items-center justify-between">
            <a class="item col-6 text-left">NIK</a>
            <q-input
              filled
              dense
              @change="isChange = true"
              outlined
              v-model="guruDetail.nik"
              class="col-6"
              lazy-rules
              :rules="[
                (val) => (val && val.length > 0) || 'NIK Tidak Boleh Kosong',
              ]"
            ></q-input>
          </div>
          <div class="row items-center justify-between">
            <a class="item col-6 text-left">NIGN</a>
            <q-input
              filled
              dense
              @change="isChange = true"
              outlined
              v-model="guruDetail.nign"
              class="col-6"
            ></q-input>
          </div>
          <div class="row items-center justify-between">
            <a class="item col-6 text-left">No.HP</a>
            <q-input
              filled
              dense
              @change="isChange = true"
              outlined
              v-model="guruDetail.hp"
              class="col-6"
            ></q-input>
          </div>
          <div class="row items-center justify-between">
            <a class="item col-6 text-left">E-mail</a>
            <q-input
              filled
              dense
              @change="isChange = true"
              outlined
              v-model="guruDetail.email"
              class="col-6"
            ></q-input>
          </div>
        </q-card-section>
        <q-card-section class="bg-indigo-1 column q-gutter-md">
          <p class="text-grey text-h6">JABATAN : (bisa lebih dari 1)</p>
          <q-checkbox
            @input="isChange = true"
            v-model="guruDetail.is_guru_mapel"
            label="Guru Mata Pelajaran"
          />
          <q-checkbox
            @input="isChange = true"
            v-model="guruDetail.is_guru_bk"
            label="Guru BK"
          />
          <q-checkbox
            @input="isChange = true"
            v-model="guruDetail.is_wali_kelas"
            label="Wali Kelas"
          />
          <q-checkbox
            @input="isChange = true"
            v-model="guruDetail.is_pembina_ekskul"
            label="Pembina Ekskul"
          />
          <q-checkbox
            @input="isChange = true"
            v-model="guruDetail.is_kurikulum"
            label="Staff Kurikulum"
          />
          <q-checkbox
            @input="isChange = true"
            v-model="guruDetail.is_kepala_sekolah"
            label="Kepala Sekolah"
          />
          <q-checkbox
            @input="isChange = true"
            v-model="guruDetail.is_super_user"
            label="Super Admin"
          />
        </q-card-section>
        <q-card-actions class="row justify-between">
          <q-btn
            flat
            color="primary"
            label="back"
            @click="
              $router.push('/akademiklistguru/' + $route.params.selJenjang)
            "
          ></q-btn>
          <q-btn
            outline
            label="Reset Password"
            color="primary"
            @click="alert = true"
            v-if="$route.params.id > 0"
          ></q-btn>
          <q-btn
            :disable="!isChange"
            type="submit"
            color="primary"
            label="simpan"
          ></q-btn>
        </q-card-actions>
      </q-form>
    </q-card>
    <q-dialog v-model="confirm">
      <q-card>
        <q-card-section>
          Apa anda yakin ingin menyimpan data?
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="close" color="primary" v-close-popup></q-btn>
          <q-btn
            unelevated
            v-if="$route.params.id < 0"
            label="Ya"
            color="primary"
            @click="tambahGuru"
            v-close-popup
          ></q-btn>
          <q-btn
            unelevated
            v-else
            label="Ya"
            color="primary"
            @click="updateGuru"
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-if="guruDetail.nik" v-model="alert" persistent>
      <q-card>
        <q-card-section>
          Tulis "{{ guruDetail.nik }}" tanpa tanda kutip untuk me-reset password
          {{ guruDetail.nama }}
          <q-input outlined dense v-model="keyword"></q-input>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="close" v-close-popup></q-btn>
          <q-btn
            unelevated
            label="RESET"
            :disable="keyword.toLowerCase() != guruDetail.nik.toLowerCase()"
            color="primary"
            @click="resetpwd"
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      confirm: false,
      alert: false,
      keyword: "",

      isChange: false,
      guruDetail: {
        is_guru_mapel: true,
        is_guru_bk: false,
        is_wali_kelas: false,
        is_pembina_ekskul: false,
        is_kurikulum: false,
        is_kepala_sekolah: false,
        is_super_user: false,
      },
      selJenjang: "",
    };
  },
  mounted() {
    this.selJenjang = this.$route.params.selJenjang;
    if (this.$route.params.id > 0) this.getGuruDetail();
  },
  methods: {
    async resetpwd() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let data = {
        id: this.guruDetail.id,
      };
      var md5 = require("md5");
      data.pass = md5(this.guruDetail.nik);
      await this.$http.put(`/akademik/guru/resetpwd`, data);
      this.$q.notify({
        message: `Password ${this.guruDetail.nama} berhasil di reset`,
        color: "positive",
      });
      this.$q.loading.hide();
      await this.updateGuru();
      this.$router.push("/akademiklistguru/" + this.selJenjang);
    },
    async getGuruDetail() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .get(
          "/akademik/guru/detail/" +
            this.$route.params.id +
            "/" +
            this.$route.params.selJenjang,
          this.$hlp.getHeader()
        )
        .then((resp) => {
          this.guruDetail = resp.data;
          this.checkfilter();
        });
      this.$q.loading.hide();
    },
    checkfilter() {
      if (this.guruDetail.is_guru_mapel == 1)
        this.guruDetail.is_guru_mapel = true;
      else this.guruDetail.is_guru_mapel = false;

      if (this.guruDetail.is_guru_bk == 1) this.guruDetail.is_guru_bk = true;
      else this.guruDetail.is_guru_bk = false;

      if (this.guruDetail.is_wali_kelas == 1)
        this.guruDetail.is_wali_kelas = true;
      else this.guruDetail.is_wali_kelas = false;

      if (this.guruDetail.is_kepala_sekolah == 1)
        this.guruDetail.is_kepala_sekolah = true;
      else this.guruDetail.is_kepala_sekolah = false;

      if (this.guruDetail.is_pembina_ekskul == 1)
        this.guruDetail.is_pembina_ekskul = true;
      else this.guruDetail.is_pembina_ekskul = false;

      if (this.guruDetail.is_kurikulum == 1)
        this.guruDetail.is_kurikulum = true;
      else this.guruDetail.is_kurikulum = false;

      if (this.guruDetail.is_super_user == 1)
        this.guruDetail.is_super_user = true;
      else this.guruDetail.is_super_user = false;
    },
    async updateGuru() {
      this.$q.loading.show({ message: "Menyimpan Perubahan" });
      await this.$http
        .put(
          "/akademik/guru/edit/" +
            this.selJenjang +
            "/" +
            this.$route.params.id,
          this.guruDetail,
          this.$hlp.getHeader()
        )
        .then((resp) => {});
      this.$q.loading.hide();
      this.$router.push("/akademiklistguru/" + this.selJenjang);
    },
    async tambahGuru() {
      this.$q.loading.show({ message: "Menyimpan Data" });
      var md5 = require("md5");
      this.guruDetail.password = md5(this.guruDetail.nik);
      await this.$http
        .post(
          "/akademik/guru/tambah/" + this.selJenjang,
          this.guruDetail,
          this.$hlp.getHeader()
        )
        .then((resp) => {});
      this.$q.loading.hide();
      this.$router.push("/akademiklistguru/" + this.selJenjang);
    },
  },
};
</script>

<style lang="scss" scoped></style>
